import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../src/redux/store";
import MainRoutes from "./Routes";
import CountDown from "./CountDown";
import Banner from "./components/banner-announcement/Banner";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();

  return (
    <>
      <ToastContainer autoClose={2000} />
      {/* <CountDown/>  */}
  
      <Provider store={store}>
        <MainRoutes />
      </Provider>
    </>
  );
};

export default App;
